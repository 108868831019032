import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  organizationsList(): string {
    return '/organizacoes';
  }

  organizationsBase(id: unknown): string {
    return [this.organizationsList(), id].join('/');
  }

  personList(): string {
    return '/pessoas';
  }

  personDetailsBase(id: unknown) {
    return [this.personList(), id].join('/');
  }

  personDetails(id: unknown) {
    const url = [this.personDetailsBase(id), 'exames'];
    return url.join('/');
  }

  personDetailsSelfieLink(id: unknown) {
    const url = [this.personDetailsBase(id), 'selfie-link'];
    return url.join('/');
  }

  personDetailsReferrals(id: unknown) {
    const url = [this.personDetailsBase(id), 'encaminhamentos'];
    return url.join('/');
  }

  personDetailsRisk(personId: unknown, id?: unknown) {
    let url;
    if (id) {
      url = [this.personDetailsBase(personId), 'riscos', id];
    } else {
      url = [this.personDetailsBase(personId), 'riscos'];
    }
    return url.join('/');
  }

  personDetailsForms(id: unknown, formSubmissionId?) {
    let url;
    if (formSubmissionId) {
      url = [this.personDetailsBase(id), 'questionario', formSubmissionId];
    } else {
      url = [this.personDetailsBase(id), 'questionario'];
    }
    return url.join('/');
  }

  personDetailsHistory(id: unknown, formSubmissionId?) {
    let url;
    if (formSubmissionId) {
      url = [this.personDetailsBase(id), 'historico', formSubmissionId];
    } else {
      url = [this.personDetailsBase(id), 'historico'];
    }
    return url.join('/');
  }

  personDetailsPersonFiles(id: unknown, formSubmissionId?) {
    let url;
    if (formSubmissionId) {
      url = [this.personDetailsBase(id), 'outros-arquivos', formSubmissionId];
    } else {
      url = [this.personDetailsBase(id), 'outros-arquivos'];
    }
    return url.join('/');
  }

  examsParses() {
    return [this.personList(), 'exames-pendentes'].join('/');
  }

  examsParsesDetail(id: unknown) {
    return [this.examsParses(), 'detalhes', id].join('/');
  }
}
