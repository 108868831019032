import { Injectable } from '@angular/core';
import { Permissions } from '../../state/models/access-control-list.model';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthStatements } from '../../state/models/auth-statements';
import { map } from 'rxjs/operators';
import {
  BasePermissionsService,
  permissionEntity,
} from '../../state/entity-management/services/base/base-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  permissions: string[];
  basePermissions = this.basePermissionsService.permissions;

  constructor(
    private basePermissionsService: BasePermissionsService,
    private http: HttpClient,
  ) {
  }

  private fetchPermissions(roleIds: string): Observable<Permissions[]> {
    const params = { roleIds };
    return this.http.get<Permissions[]>(`${ environment.apiUrl }/permissions`, { params }).pipe(
      map((response: any) => response?.permissions as Permissions[]),
      tap(permissions => {
        this.permissions = permissions.map(permission => {
          return permission.name;
        });
      }),
    );
  }

  public getRoles(personId: number): Observable<Permissions[]> {
    const principalPersonIds = personId;
    const params = { principalPersonIds };
    return this.http.get<AuthStatements[]>(`${ environment.apiUrl }/auth-statements`, { params }).pipe(
      map((response: any) => response?.authStatements as AuthStatements[] ?? []),
      switchMap(response => {
        const ids = response.map(authStatement => {
          return authStatement.role.id;
        });
        return this.fetchPermissions(ids.toString());
      }),
    );
  }

  /**
   * Checks if the specified permission exists within the user's permissions.
   *
   * @param {string} permission - The permission to check.
   * @returns {boolean} - Returns `true` if the permission is found, otherwise `false`.
   */
  public hasPermission(permission: string): boolean {
    return this.permissions.some(p => {
      return p === permission;
    });
  }

  /**
   * Checks if the user has permission for a specific entity and action.
   *
   * @param {permissionEntity} entity - The entity to check the permission for.
   * @param {string} action - The action to check the permission for.
   * @returns {boolean} - Returns `true` if the permission is found for the entity and action, otherwise `false`.
   */
  public hasPermissionEntity(entity: permissionEntity, action: string): boolean {
    return this.permissions.some(p => {
      return p === `${ this.basePermissions[entity][action] }`;
    });
  }


  /**
   * Checks if the user has any of the specified permissions, using the `permissionControl` directive.
   *
   * @param {string[]} permission - An array of permissions to check, where each item follows the format 'entity#action'.
   * @returns {boolean} - Returns `true` if at least one of the specified permissions is found, otherwise `false`.
   */
  public hasPermissions(permission: string[]): boolean {
    const permissionsName = [];
    permission.forEach(item => {
      const [entity, action] = item.split('#');
      if (this.basePermissions[entity][action]) {
        permissionsName.push(this.basePermissions[entity][action]);
      }
    });
    return this.permissions.some(p => {
      return permissionsName.includes(p);
    });
  }

}

