{
  "name": "professional",
  "version": "1.24.1",
  "license": "https://themeforest.net/licenses/terms/regular",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port=4200 --open --configuration=dev",
    "serveProd": "ng serve --environment=prod",
    "build": "ng build --configuration=production --aot=true --optimization=true",
    "build:stag": "ng build --configuration=stag --aot=true --optimization=true",
    "test": "ng test",
    "e2e": "ng e2e",
    "format:fix": "pretty-quick --staged",
    "format:check": "prettier --config ./.prettierrc --list-different \"src/{app,environments,assets}/**/*{.ts,.js,.json,.css,.scss}\"",
    "precommit": "run-s format:fix lint",
    "lint": "tslint --fix -c ./tslint.json 'src/**/*{.ts}'",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org mar-saude --project professional ./dist && sentry-cli sourcemaps upload --org mar-saude --project professional ./dist",
    "update-angular": "ng update @angular/core @angular/cli @angular/cdk @angular-devkit/build-angular @angular/compiler-cli @angular/material @angular/material-experimental",
    "has-changes": "git log main..dev --pretty=format:\"%s%n%b\"",
    "changelog-version": "node generate-changelog-update-version.js",
    "commit-version": "git add . && git commit -m'Version name: '$(grep -m1 version package.json | awk -F: '{ print $2 }' | sed 's/[\", ]//g')''",
    "release": "npm run changelog-version && npm run commit-version && git push && git checkout main && git pull && git merge --no-ff dev && git push && git checkout -"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.1.0",
    "@angular/cdk": "18.1.0",
    "@angular/common": "18.1.0",
    "@angular/compiler": "18.1.0",
    "@angular/core": "18.1.0",
    "@angular/flex-layout": "15.0.0-beta.42",
    "@angular/forms": "18.1.0",
    "@angular/material": "18.1.0",
    "@angular/material-experimental": "^18.1.0",
    "@angular/platform-browser": "18.1.0",
    "@angular/platform-browser-dynamic": "18.1.0",
    "@angular/router": "18.1.0",
    "@ngx-formly/core": "^6.3.0",
    "@ngx-formly/material": "^6.3.6",
    "@sentry/angular": "^8.13.0",
    "@sentry/cli": "^2.32.1",
    "@swimlane/ngx-datatable": "^20.0.0",
    "@types/pdfjs-dist": "^2.10.378",
    "angular-cd-timer": "^3.0.0",
    "angular-imask": "^7.6.1",
    "core-js": "3.38.0",
    "date-fns": "2.14.0",
    "dayjs": "^1.11.7",
    "iframe-resizer": "^4.4.2",
    "install": "0.12.2",
    "ng2-currency-mask": "^13.0.3",
    "ng2-file-upload": "1.4.0",
    "ngx-color-picker": "^10.1.0",
    "ngx-file-drop": "^15.0.0",
    "ngx-image-cropper": "^8.0.0",
    "ngx-pagination": "5.0.0",
    "npm": "^10.6.0",
    "numeral": "^2.0.6",
    "pdfjs-dist": "3.2.146",
    "quill": "^2.0.0",
    "rxjs": "7.8.1",
    "tinycolor2": "^1.4.1",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular-devkit/core": "18.1.0",
    "@angular/cli": "18.1.0",
    "@angular/compiler-cli": "18.1.0",
    "@angular/language-service": "18.1.0",
    "@types/hopscotch": "0.2.30",
    "@types/node": "^22.1.0",
    "@types/numeral": "^2.0.5",
    "codelyzer": "^6.0.0",
    "copy-webpack-plugin": "^10.2.4",
    "enhanced-resolve": "5.17.0",
    "husky": "^4.3.0",
    "npm-run-all": "^4.1.5",
    "prettier": "^3.3.3",
    "pretty-quick": "^4.0.0",
    "semver": "^7.6.0",
    "sonar-scanner": "^3.1.0",
    "ts-node": "10.9.2",
    "tslint": "~6.1.0",
    "tslint-angular": "^3.0.3",
    "typescript": "5.4.5"
  },
  "description": "Este projeto é uma aplicação desenvolvida em Angular, destinada a otimizar o sistema de atendimento interno da Mar saúde. Com uma interface amigável e recursos avançados, visa proporcionar uma experiência eficiente tanto para os profissionais de saúde quanto para os pacientes.",
  "main": "generate-changelog-update-version.js",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/mar-saude/professional.git"
  },
  "keywords": [],
  "author": "",
  "bugs": {
    "url": "https://github.com/mar-saude/professional/issues"
  },
  "homepage": "https://github.com/mar-saude/professional#readme"
}