import { Injectable } from '@angular/core';
import { BehaviorSubject, noop } from 'rxjs';
import { UrlService } from './url.service';
import { RouterParamsService } from './router-params.service';
import { filter, tap } from 'rxjs/operators';
import { JwtAuthService } from './auth/jwt-auth.service';
import { AclService } from './acl.service';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  fragment?: string; // Router fragment
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}

interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  fragment?: string; // Router fragment
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  iconMenu: IMenuItem[] = [];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor(
    private aclService: AclService,
    private jwtAuthService: JwtAuthService,
    private routerParamsService: RouterParamsService,
    private urlService: UrlService,
  ) {
    this.routerParamsService.params
      .pipe(
        filter(params => !!params['personId']),
        tap(params => {
          const { personId } = params;
          this.iconMenu = [];
          this.setNavPerson(personId);
          this.menuItems.next(this.iconMenu);
        }),
      )
      .subscribe(noop);
  }

  createSubNavRisk(fragmentList = [], personId) {
    this.iconMenu.forEach(im => {
      if (im.name === 'Riscos') {
        im.type = 'dropDown';
        im.sub = fragmentList.map(f => {
          return {
            name: f.nameTranslated,
            state: '',
            fragment: f.label,
            type: 'link',
          };
        });
      }
    });
    this.menuItems.next(this.iconMenu);
  }

  setNavGeneral() {
    this.iconMenu = [];
    this.iconMenu.push({
      name: 'Pacientes',
      type: 'link',
      tooltip: 'Antecedentes Pessoais',
      icon: 'person',
      state: this.urlService.personList(),
    });
    this.menuItems.next(this.iconMenu);
  }

  setNavPerson(personId) {
    this.iconMenu.push(
      {
        name: 'Riscos',
        type: 'link',
        tooltip: 'Riscos',
        icon: 'calculate',
        state: this.urlService.personDetailsRisk(personId),
        disabled: !this.aclService.hasPermissionEntity('selfieConsolidated', 'read'),
      },
      {
        name: 'Exames',
        type: 'link',
        tooltip: 'Exames',
        icon: 'monitor_heart',
        state: this.urlService.personDetails(personId),
        disabled: !(
          this.aclService.hasPermissionEntity('performedExam', 'read') ||
          this.aclService.hasPermissionEntity('examRequest', 'read') ||
          this.aclService.hasPermissionEntity('examParse', 'read')
        ),
      },
      {
        name: 'Encaminhamentos',
        type: 'link',
        tooltip: 'Encaminhamentos',
        icon: 'forward',
        state: this.urlService.personDetailsReferrals(personId),
        disabled: !this.aclService.hasPermissionEntity('referral', 'read'),
      },
      {
        name: 'Questionários',
        type: 'link',
        tooltip: 'Questionários',
        icon: 'inventory',
        state: this.urlService.personDetailsForms(personId),
        disabled: !this.aclService.hasPermissionEntity('formSubmission', 'read'),
      },
      {
        name: 'Histórico',
        type: 'link',
        tooltip: 'Histórico',
        icon: 'history',
        state: this.urlService.personDetailsHistory(personId),
        disabled: !(
          this.aclService.hasPermissionEntity('patientNote', 'read') ||
          this.aclService.hasPermissionEntity('appointment', 'read')
        ),
      },
    );
  }
}
